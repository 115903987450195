
.language-dropdown {
  .dropdown-trigger {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 50px;
    padding: 10px;
    background: #fff;
    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      .flag {
        margin: 0 !important;
      }
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .dropdown-menu {
    .dropdown-content {
      border-radius: 10px;
      padding: 10px;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(142, 142, 142);
        width: 8px;
        border-radius: 5px;
        background-clip: content-box;
        border: 2px solid transparent;
      }
      .dropdown-item {
        display: flex;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 5px;
        .flag {
          margin: 0 !important;
        }
        &:hover {
          background: #f0f0f0;
          color: #000;
        }
        &.is-active {
          background: #007aff;
          &:hover {
            color: #fff;
          }
        }
      }
      .input {
        background-color: rgb(234, 234, 234) !important;
      }
      .icon.is-right {
        height: 100%;
      }
    }
  }
}
.no-flag {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #ccc;
  margin: 10px;
  justify-content: center;
  align-items: center;
  &::before {
    content: "?";
    color: rgb(138, 138, 138);
    font-weight: 600;
  }
}
